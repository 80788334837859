import React from 'react';
import flow from 'lodash/flow';
import get from 'lodash/get';
import identity from 'lodash/identity';
import { withStateHandlers } from 'recompose';

const Focusable = (WrappedComponent: any) => {
  const FocusableComponent = ({
    isFocused,
    ...props
  }: any) => (
    <WrappedComponent
      isFocused={isFocused}
      {...props} // eslint-disable-line
    />
  );

  return flow(
    withStateHandlers(
      (props: any) => ({
        isFocused: get(props, 'isFocused', false),
      }),
      {
        onFocus: (state: any, props: any) => (event: any) => {
          const cb = get(props, 'onFocus', identity);

          cb(event);

          return {
            isFocused: true,
          };
        },
        onBlur: (state: any, props: any) => (event: any) => {
          const cb = get(props, 'onBlur', identity);

          cb(event);

          return {
            isFocused: false,
          };
        },
      },
    ),
  )(FocusableComponent);
};

export default Focusable;
