import styled from 'styled-components';
import theme from '../../theme';

interface LabelProps {
  isFocused: boolean,
  value: any,
}

const Label = styled.div`
  ${theme.formLabel()}
`;

const FormLabel = styled(Label)`
  ${(props: LabelProps) => {
    if (!props.isFocused) return '';

    return `
      margin-top: -14px;
      font-size: 13px;
    `;
  }}

  ${(props: LabelProps) => {
    if (!props.value) return '';

    return `
      margin-top: -14px;
      font-size: 13px;
    `;
  }}
`;

export default FormLabel;
