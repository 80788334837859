import tail from 'lodash/tail';
import isEmail from 'validator/lib/isEmail';

type validateFnType = (value: any) => string | undefined;

export const composedFieldValidation = (
  value: any,
  validations: Array<validateFnType>,
): string | undefined => {
  if (!validations.length) return undefined;

  const validateFn = validations[0];

  const error = validateFn(value);

  if (error) return error;

  return composedFieldValidation(value, tail(validations));
};

export const required = (value: any) => (value || typeof value === 'number' ? undefined : 'Må fylles ut');

export const email = (value: any) => (isEmail(value) ? undefined : 'Vennligst skriv inn en gyldig e-post adresse.');

export const informParent = (event: any = {}, data: any = {}) => {
  const body = document.querySelector('body');
  const { type = '' } = event;
  const message = { ...data, type };

  // @ts-ignore
  body.dispatchEvent(event);

  // Using CORS + postMessage
  try {
    window.parent.window.postMessage(message, '*');

    return;
  } catch (err) {
    // eslint-disable-next-line
    console.log('Exception calling window.parent.window.postMessage');
  }

  if (window.self !== window.top) {
    try {
      // Using same domain, without security limits
      // @ts-ignore
      window.parent.document.querySelector('body').dispatchEvent(event);
    } catch (err) {
      // eslint-disable-lin
      console.log('Exception calling window.parent.document.querySelector("body").dispatchEvent');
    }
  }
};
