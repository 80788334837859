import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Form from '../Form';
import ExpandButton from '../ExpandButton';

const Root: React.FC = () => (
  <>
    <Router>
      <Switch>
        <Route exact path="/form">
          <Form />
        </Route>

        <Route exact path="/button">
          <ExpandButton />
        </Route>

        <Route>
          <Form />
        </Route>
      </Switch>
    </Router>
  </>
);

export default Root;
