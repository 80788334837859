import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 10px;
`;

const Icon = styled.svg`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

const Path = styled.path`
  fill: ${theme.colors.cerulean};
`;

const SuccessSign = () => (
  <Container>
    <Icon version="1.1" x="0px" y="0px" width="15px" height="11px" viewBox="0 0 15 11">
      <Path d="M5.347,8.936l8.5-8.638c0.252-0.256,0.66-0.257,0.91-0.001c0.254,0.257,0.252,0.669,0,0.924l-9.07,9.218
          c-0.256,0.26-0.659,0.255-0.91,0c-0.007-0.007-4.274-4.311-4.274-4.311c-0.251-0.255-0.251-0.668,0-0.924
          c0.253-0.257,0.658-0.255,0.909,0l3.819,3.849L5.347,8.936z"
      />
    </Icon>
  </Container>
);

export default SuccessSign;
