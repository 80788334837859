import React from 'react';
import styled from 'styled-components';
import Input from '../Input';
import FormAlert from '../FormAlert';
import theme from '../../theme';

interface FormInputProps {
  type: string,
  id: string,
  name: string,
  label?: string,
  value?: string,
  errorMsg?: string,
  disableAutocomplete?: boolean | undefined | null | string,
  isError?: boolean,
  isValid?: boolean,
  isDisabled?: boolean,
  onChange: () => void,
  onBlur: () => void,
  onFocus: () => void,
}

const Container = styled.div``;

const Wrapper = styled.div``;

const Content = styled.div``;

const Field = styled.div``;

const Footer = styled.div`
  ${theme.errorPlacement()}
`;

const FormInput: React.FC<FormInputProps> = ({
  type,
  id,
  name,
  label,
  value,
  errorMsg,
  disableAutocomplete,
  isError,
  isValid,
  isDisabled,
  onChange,
  onBlur,
  onFocus,
}) => (
  <Container>
    <Wrapper>
      <Content>
        <Field>
          <Input
            type={type}
            id={id}
            name={name}
            label={label}
            value={value}
            isError={isError}
            isValid={isValid}
            isDisabled={isDisabled}
            disableAutocomplete={disableAutocomplete}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </Field>
      </Content>

      {isError && (<Footer><FormAlert text={errorMsg || ''} /></Footer>)}
    </Wrapper>
  </Container>
);

export default FormInput;
