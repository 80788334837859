import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import theme from '../../theme';

const Container = styled.div`
  padding: 0 25px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 0 auto 20px auto;
`;

const Svg = styled.svg`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

const Path = styled.path`
  fill: ${theme.colors.california};
`;

const Title = styled.h4`
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  color: ${theme.colors.mineShaft};
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  color: ${rgba(theme.colors.mineShaft, 0.8)};
  text-align: center;
`;

const FormResult: React.FC = () => (
  <Container>
    <Icon>
      <Svg version="1.1" x="0px" y="0px" width="120px" height="119px" viewBox="0 0 120 119">
        <Path d="M80.5,106H2.995C1.343,105.999,0,104.654,0,103.002V40.643c0-1.567,1.003-3.022,2.752-3.992l16.106-11.557
          c0.044-0.032,0.092-0.055,0.142-0.071V10h21.083c0.032-0.049,0.074-0.093,0.124-0.129L54.003-0.12L67.665,10H88v14.981
          l16.234,11.524c1.786,0.958,2.766,2.919,2.766,3.955V78.5c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V40.46
          c0-0.312-0.165-0.825-0.493-1.369L88,50.407V50.5c0,0.381-0.491,0.614-0.782,0.413L58.384,69.55l23.922,18.455
          c0.219,0.168,0.259,0.482,0.09,0.701c-0.168,0.218-0.483,0.259-0.701,0.09l-24.202-18.67l-3.044,1.968l-4.823-3.063L2.799,103.901
          c-0.223,0.164-0.536,0.118-0.7-0.103c-0.165-0.222-0.119-0.535,0.102-0.7l46.521-34.643L19.745,50.048
          C19.448,50.215,19,49.978,19,49.612v-0.038L2.232,38.922c-0.166-0.105-0.249-0.292-0.229-0.475C1.349,39.106,1,39.862,1,40.643
          v62.359c0,1.101,0.895,1.997,1.996,1.998H80.5c0.276,0,0.5,0.224,0.5,0.5S80.776,106,80.5,106z M20,49.025l34.443,21.881
          l2.675-1.729c0.066-0.079,0.152-0.132,0.245-0.158L87,49.862V25.495c-0.194-0.167-0.233-0.457-0.083-0.669
          c0.024-0.034,0.053-0.064,0.083-0.09V11H67.505c-0.007-0.001-0.008,0-0.011,0H20V49.025z M88,26.207v23.009l16.904-10.926
          c-0.326-0.354-0.725-0.683-1.196-0.937L88,26.207z M2.512,38c0.088,0.002,0.176,0.027,0.256,0.078L19,48.39V26.223L3.287,37.494
          C3.004,37.652,2.745,37.821,2.512,38z M41.733,10h24.252L53.997,1.12L41.733,10z M69.5,50h-31c-0.276,0-0.5-0.224-0.5-0.5
          s0.224-0.5,0.5-0.5h31c0.276,0,0.5,0.224,0.5,0.5S69.776,50,69.5,50z M77.5,40h-48c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h48
          c0.276,0,0.5,0.224,0.5,0.5S77.776,40,77.5,40z M77.5,31h-48c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h48
          c0.276,0,0.5,0.224,0.5,0.5S77.776,31,77.5,31z"
        />

        <Path
          d="M119,98.5c0-10.77-8.73-19.5-19.5-19.5S80,87.73,80,98.5S88.73,118,99.5,118S119,109.27,119,98.5z M120,98.5c0,11.322-9.178,20.5-20.5,20.5S79,109.822,79,98.5S88.178,78,99.5,78S120,87.178,120,98.5z M108.795,95.395l-11.32,11.396l-8.27-8.325l2.17-2.185l6.1,6.138l9.15-9.21L108.795,95.395z M106.625,91.791l-9.15,9.21l-6.1-6.14l-3.58,3.604l9.68,9.743l12.73-12.814L106.625,91.791z"
        />
      </Svg>
    </Icon>

    <Title>
      Meldingen er sendt!
    </Title>

    <Text>
      Takk for at du sier ifra, det er veldig viktig for oss!
    </Text>
  </Container>
);

export default FormResult;
