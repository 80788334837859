import React, { useState } from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import { rgba } from 'polished';
import theme from '../../theme';

interface StarProps {
  isHovered: boolean,
  isActive: boolean,
  isDisabled: boolean,
  onClick: any,
  onEnter: any,
  onLeave: any,
}

interface StarButtonProps {
  disabled: boolean,
}

interface StartPathProps extends StarProps {
  isHovered: boolean,
}

const StarButton = styled.button`
  display: flex;
  width: 25px;
  height: 22px;
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;

  ${(props: StarButtonProps) => {
    if (!props.disabled) return '';

    return `
        cursor: not-allowed;
      `;
  }}
`;

const StarContainer = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
`;

const StartPath = styled.path`
  fill: ${rgba(theme.colors.shuttleGray, 0.2)};
  transition: fill ${theme.transition};

  ${(props: StartPathProps) => {
    if (!props.isHovered) return '';

    return `fill: ${rgba(theme.colors.shuttleGray, 0.6)};`;
  }}

  ${(props: StartPathProps) => {
    if (!props.isActive) return '';

    return `fill: ${theme.colors.cerulean};`;
  }}
`;

const Star: React.FC<StarProps> = ({
  isHovered,
  isActive,
  isDisabled,
  onClick,
  onEnter,
  onLeave,
}) => {
  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);

    onEnter();
  };

  const onMouseLeave = () => {
    setHovered(false);

    onLeave();
  };

  return (
    <StarButton
      type="button"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={isDisabled}
    >
      <StarContainer
        version="1.1"
        x="0px"
        y="0px"
        width="25px"
        height="22px"
        viewBox="0 0 25 22"
      >
        <StartPath
          isActive={isActive}
          isHovered={hovered || isHovered}
          d="M11.618,0.676c0.485-0.902,1.279-0.901,1.764,0l2.397,4.457c0.486,0.902,1.771,1.758,2.854,1.903
        l5.361,0.714c1.085,0.145,1.33,0.837,0.546,1.539l-3.88,3.47c-0.785,0.7-1.276,2.086-1.091,3.077l0.916,4.898
        c0.186,0.99-0.457,1.42-1.426,0.951l-4.797-2.312c-0.97-0.468-2.558-0.468-3.528,0L5.94,21.686c-0.97,0.467-1.613,0.039-1.427-0.951
        l0.915-4.899c0.186-0.99-0.305-2.375-1.09-3.077L0.46,9.288c-0.784-0.7-0.54-1.393,0.546-1.538l5.361-0.714
        c1.085-0.145,2.37-1,2.854-1.903L11.618,0.676z"
        />
      </StarContainer>
    </StarButton>
  );
};

interface FormScoreProps {
  id: string,
  name: string,
  label?: string,
  value: number,
  onChange: any,
  isDisabled?: boolean,
}

const maxScore = 5;

const Container = styled.div`
  padding-bottom: 5px;

  @media (${theme.breakpoints.S}) {
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div``;

const Header = styled.div``;

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.4;
  color: ${theme.colors.mineShaft};

  @media (${theme.breakpoints.S}) {
    margin-bottom: 5px;
  }
`;

const Content = styled.div``;

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const Item = styled.li`
  margin-right: 10px;

  :nth-last-child(-n + 1) {
    margin-right: 0;
  }
`;

const FormScore: React.FC<FormScoreProps> = ({
  name,
  label,
  value,
  isDisabled,
  onChange,
}) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState(0);

  const onEnter = (idx: number) => setHoveredItemIndex(idx);

  const onLeave = () => setHoveredItemIndex(0);

  return (
    <Container>
      <Wrapper>
        <Header>
          <Label>{label}</Label>
        </Header>

        <Content>
          <List>
            {
              range(maxScore).map((_, idx) => {
                const score = idx + 1;

                return (
                  <Item
                    key={score} // eslint-disable-line
                  >
                    <Star
                      isHovered={idx < hoveredItemIndex}
                      isActive={(score) <= value}
                      onClick={() => onChange(name, score)}
                      isDisabled={!!isDisabled}
                      onEnter={() => onEnter(idx)}
                      onLeave={() => onLeave()}
                    />
                  </Item>
                );
              })
            }
          </List>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default FormScore;
