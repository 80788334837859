import React from 'react';
import styled from 'styled-components';
import ErrorSign from '../ErrorSign';
import theme from '../../theme';

interface FormAlertProps {
  withIcon?: boolean | null,
  text: string,
}

const Container = styled.div`
  position: relative;
  padding: 5px 0;
`;

const Icon = styled.div`
  position: absolute;
  display: block;
  top: 4px;
  left: 0;
`;

const Text = styled.div`
  font-size: 13px;
  line-height: 1;
  color: ${theme.colors.persimmon};

  @media (${theme.breakpoints.S}) {
    font-size: 12px;
  }
`;

const Paragraph = styled.p`
  margin: 0;
`;

const FormAlert: React.FC<FormAlertProps> = ({
  withIcon,
  text,
}) => (
  <Container>
    {withIcon && (
      <Icon>
        <ErrorSign size="small" />
      </Icon>
    )}

    <Text>
      <Paragraph>{text}</Paragraph>
    </Text>
  </Container>
);

export default FormAlert;
