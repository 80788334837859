import React from 'react';
import flow from 'lodash/flow';
import { Field, FieldProps } from 'formik';
import FocusableComponent from '../../HOC/FocusableComponent';

export interface fieldProps {
  name: string,
  value: any,
  error: boolean,
  errorMsg: string,
  isValid: boolean,
  onFocus: any,
  onBlur: any,
  onChange: any,
  setFieldValue: any,
}

interface errorFnProps {
  submitFailed: boolean,
  isActive: boolean,
  isTouched: boolean,
  isError: boolean,
  value: any,
  initialValue: any,
}

const errorFn = ({
  submitFailed,
  isActive,
  isTouched,
  isError,
  value,
  initialValue,
}: errorFnProps) => {
  if (!isTouched) return false;

  if (isActive) return false;

  if (initialValue === value && !submitFailed) return false;

  if (isTouched && isError) return true;

  return false;
};

interface errorMsgFnProps {
  submitFailed: boolean,
  isActive: boolean,
  errorMessage: any,
}

const errorMsgFn = ({ submitFailed, isActive, errorMessage }: errorMsgFnProps) => {
  if (!isActive && submitFailed) return errorMessage;

  return !isActive ? errorMessage : null;
};

interface validFnProps {
  isActive: boolean,
  isTouched: boolean,
  isError: boolean,
}

const validFn = ({
  isActive,
  isTouched,
  isError,
}: validFnProps) => !isActive && isTouched && !isError;

interface ComposedFieldProps {
  children: (props: fieldProps) => void,
  name: string,
  isFocused: boolean,
  validate: () => void,
  onFocus: () => void,
  onBlur: () => void,
}

const ComposedField: React.FC<ComposedFieldProps> = ({
  children,
  name,
  validate,
  isFocused,
  onFocus,
  onBlur,
  ...props
}) => (
  <Field
    name={name}
    validate={validate}
  >
    {({ field, form, meta }: FieldProps) => children({
      ...props,
      ...field,
      ...form,
      name: field.name,
      value: field.value,
      onFocus,
      onBlur: (event: any) => {
        field.onBlur(event);

        onBlur();
      },
      error: errorFn({
        submitFailed: !form.isValid && form.submitCount > 0,
        isActive: isFocused,
        isTouched: meta.touched,
        isError: !!meta.error,
        value: field.value,
        initialValue: form.initialValues[name],
      }),
      errorMsg: errorMsgFn({
        submitFailed: form.isValid && form.submitCount > 0,
        isActive: isFocused,
        errorMessage: form.errors[name],
      }),
      isValid: validFn({
        isActive: isFocused,
        isTouched: meta.touched,
        isError: !!meta.error,
      }),
    })}
  </Field>
);

export default flow(
  FocusableComponent,
)(ComposedField);
