import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import axios from 'axios';
import queryString from 'query-string';
import { rgba, darken } from 'polished';
import get from 'lodash/get';
import theme from '../../theme';
import FormInput from '../FormInput';
import FormTextArea from '../FormTextArea';
import FormScore from '../FormScore';
import Preloader from '../Preloader';
import FormResult from '../FormResult';
import ComposedField, { fieldProps as fieldPropsType } from '../ComposedField';
import {
  informParent,
  composedFieldValidation,
  required,
  email,
} from '../../utils';

const validateEmailFn = (value: any) => composedFieldValidation(value, [required, email]);
const validateTextFn = (value: any) => composedFieldValidation(value, [required]);

const Container = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  margin: ${theme.iframeSpacing};
  border-radius: 8px;
  box-shadow: 0 3px 12px 1px rgba(0, 0, 0, 0.2);
  background-color: ${theme.colors.white};
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 0 24px;
  background-color: ${theme.colors.california};

  @media (${theme.breakpoints.S}) {
    height: 40px;
  }
`;

const Title = styled.h4`
  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;
  color: ${theme.colors.white};

  @media (${theme.breakpoints.S}) {
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;

  ::before,
  ::after {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${theme.colors.white};
    cursor: pointer;
  }

  ::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  ::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const Content = styled.div`
  position: relative;
  padding: 25px 24px;

  @media (${theme.breakpoints.S}) {
    padding: 20px 24px;
  }
`;

const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

interface FormContainerProps {
  isFinished: boolean,
}

const FormContainer = styled.form`
  ${(props: FormContainerProps) => {
    if (!props.isFinished) return '';

    return 'visibility: hidden';
  }}
`;

const Rows = styled.div`
  margin-bottom: 16px;
`;

const Row = styled.div`
  margin-bottom: 25px;

  :nth-last-child(-n + 1) {
    margin-bottom: 0;
  }
`;

interface SubmitButtonProps {
  disabled: boolean,
}

const SubmitButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 52px;
  margin: 0;
  padding: 0;
  appearance: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  color: ${theme.colors.white};
  border: none;
  border-radius: 4px;
  background-color: ${theme.colors.cerulean};
  cursor: pointer;
  transition: background-color ${theme.transition};

  :hover {
    background-color: ${darken(0.03, theme.colors.cerulean)};
  }

  ${(props: SubmitButtonProps) => {
    if (!props.disabled) return '';

    return `
      background: ${rgba(theme.colors.cerulean, 0.6)};
      cursor: not-allowed;
    `;
  }}

  @media (${theme.breakpoints.S}) {
    height: 46px;
  }
`;

const SubmitButtonText = styled.div`
  ${(props: SubmitButtonProps) => {
    if (!props.disabled) return '';

    return 'opacity: 0;';
  }}
`;

const SubmitButtonPreloader = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);

  ${(props: SubmitButtonProps) => {
    if (!props.disabled) return '';

    return 'opacity: 1;';
  }}
`;

const Result = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
`;

const Error = styled.div`
  width: calc(100% - 157px);
  padding-right: 10px;
  font-size: 13px;
  line-height: 1.3;
  color: ${theme.colors.carnation};
`;

interface FormValues {
  score: number,
  title: string,
  text: string,
  email: string,
  env: string,
}

interface Message {
  type: string,
  data: string,
}

const onClose = () => {
  const event = document.createEvent('Event');

  event.initEvent('reporter:collapse', true, true);

  informParent(event);
};

const timeout = 4000;

const submitDelay = 10000;

const Form: React.FC = () => {
  const [submittingState, setSubmittingState] = useState('idle');

  const submitIdle = () => setSubmittingState('idle');
  const submitCapture = () => setSubmittingState('capturing');
  const submitRequest = () => setSubmittingState('requested');
  const submitFailure = () => setSubmittingState('failed');
  const submitSuccess = () => setSubmittingState('finished');

  const isCapturing = submittingState === 'capturing';
  const isRequested = submittingState === 'requested';
  const isFailed = submittingState === 'failed';
  const isFinished = submittingState === 'finished';

  const isDisabled = isCapturing || isRequested;

  const { endpoint } = queryString.parse(window.location.search);

  const initialValues: FormValues = {
    score: 0,
    title: '',
    text: '',
    email: '',
    env: '',
  };

  const sendDataToServer = async (values: FormValues, resetForm: () => void) => {
    submitRequest();

    try {
      // @ts-ignore
      await axios.post(endpoint, values);

      submitSuccess();

      setTimeout(() => {
        resetForm();

        submitIdle();
      }, timeout);
    } catch (error) {
      submitFailure();
    }
  };

  const onSubmit = (values: FormValues, { resetForm }: any) => {
    submitCapture();

    let timer: number = 0;

    const handleMessage = (message: Message) => {
      const type = get(message, 'data.type');

      if (type !== 'reporter:message') return;

      const data = get(message, 'data.data');

      clearTimeout(timer);

      window.removeEventListener('message', handleMessage);

      sendDataToServer({ ...values, env: data }, resetForm);
    };

    window.addEventListener('message', handleMessage);

    const event = document.createEvent('Event');

    event.initEvent('reporter:submit', true, true);

    informParent(event);

    timer = setTimeout(() => {
      window.removeEventListener('message', handleMessage);

      sendDataToServer(values, resetForm);
    }, submitDelay);
  };

  useEffect(() => {
    if (endpoint) return;

    // eslint-disable-next-line
    console.warn('🤯🤖🥶 Please provide an endpoint for error reporter form! ☠️👻💩');
  }, [endpoint]);

  return (
    <Container>
      <Wrapper>
        <Header>
          <Title>
            Rapporter feil
          </Title>

          <CloseButton type="button" onClick={onClose} aria-label="Lukke" />
        </Header>

        <Content>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {(formProps) => (
              <FormContainer
                onSubmit={formProps.handleSubmit}
                noValidate
                isFinished={isFinished}
              >
                <Rows>
                  <Row>
                    <ComposedField
                      name="score"
                    >
                      {
                        (fieldProps: fieldPropsType) => (
                          <FormScore
                            id="bcdd8737-da45-41a7-80fe-31294463d20a"
                            name={fieldProps.name}
                            label="Hvordan opplever du tjenesten?"
                            value={fieldProps.value}
                            onChange={fieldProps.setFieldValue}
                            isDisabled={isDisabled}
                          />
                        )
                      }
                    </ComposedField>
                  </Row>

                  <Row>
                    <ComposedField
                      name="title"
                    >
                      {
                        (fieldProps: fieldPropsType) => (
                          <FormInput
                            type="text"
                            id="6595c5c1-cd9c-4368-bab9-e1b4ab69cadb"
                            name={fieldProps.name}
                            label="Tittel"
                            value={fieldProps.value}
                            onChange={fieldProps.onChange}
                            onBlur={fieldProps.onBlur}
                            onFocus={fieldProps.onFocus}
                            isDisabled={isDisabled}
                          />
                        )
                      }
                    </ComposedField>
                  </Row>

                  <Row>
                    <ComposedField
                      name="email"
                      validate={validateEmailFn}
                    >
                      {
                        (fieldProps: fieldPropsType) => (
                          <FormInput
                            type="text"
                            id="53e2390b-497b-483e-8b95-a8cb642e1e4f"
                            name={fieldProps.name}
                            label="E-post"
                            value={fieldProps.value}
                            isError={fieldProps.error}
                            errorMsg={fieldProps.errorMsg}
                            isValid={fieldProps.isValid}
                            onChange={fieldProps.onChange}
                            onBlur={fieldProps.onBlur}
                            onFocus={fieldProps.onFocus}
                            isDisabled={isDisabled}
                          />
                        )
                      }
                    </ComposedField>
                  </Row>

                  <Row>
                    <ComposedField
                      name="text"
                      validate={validateTextFn}
                    >
                      {
                        (fieldProps: fieldPropsType) => (
                          <FormTextArea
                            id="a3e05627-1eae-4098-8037-83249dd98c4c"
                            name={fieldProps.name}
                            label="Beskriv feilen"
                            value={fieldProps.value}
                            isError={fieldProps.error}
                            errorMsg={fieldProps.errorMsg}
                            isValid={fieldProps.isValid}
                            onChange={fieldProps.onChange}
                            onBlur={fieldProps.onBlur}
                            onFocus={fieldProps.onFocus}
                            isDisabled={isDisabled}
                          />
                        )
                      }
                    </ComposedField>
                  </Row>
                </Rows>

                <Action>
                  {isFailed && (
                    <Error>
                      Beklager, det oppstod en feil. Vennligst prøv igjen.
                    </Error>
                  )}

                  <SubmitButton
                    type="submit"
                    disabled={isDisabled}
                  >
                    <SubmitButtonText disabled={isDisabled}>
                      Send
                    </SubmitButtonText>

                    <SubmitButtonPreloader disabled={isDisabled}>
                      <Preloader />
                    </SubmitButtonPreloader>
                  </SubmitButton>
                </Action>
              </FormContainer>
            )}
          </Formik>

          {isFinished && (
            <Result>
              <FormResult />
            </Result>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Form;
