import React from 'react';
import flow from 'lodash/flow';
import styled from 'styled-components';
import SuccessSign from '../SuccessSign';
import ErrorSign from '../ErrorSign';
import FocusableComponent from '../../HOC/FocusableComponent';
import FormLabel from '../FormLabel';
import FormField from '../FormField';
import theme from '../../theme';

interface InputProps {
  type: string,
  name: string,
  id: string,
  label?: string,
  value?: string,
  className?: string,
  placeholder?: string,
  disableAutocomplete?: boolean | undefined | null | string,
  isReadonly?: boolean,
  isError?: boolean,
  isFocused?: boolean,
  isValid?: boolean,
  isDisabled?: boolean,
  onChange: any,
  onBlur: any,
  onFocus: any,
}

const Container = styled.div`
  position: relative;
  display: block;
`;

const Content = styled.label`
  position: relative;
  display: block;
`;

const Status = styled.div`
  ${theme.fieldStatus()}
`;

const Input: React.FC<InputProps> = ({
  type,
  name,
  id,
  label,
  value,
  placeholder,
  isReadonly,
  isError,
  isValid,
  isDisabled,
  isFocused,
  onChange,
  onBlur,
  onFocus,
}) => (
  <Container>
    <Content htmlFor={id}>
      <FormField
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={isDisabled ? 'disabled' : undefined}
        readOnly={isReadonly ? 'readonly' : undefined}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        isError={isError ? 'error' : undefined}
      />

      <FormLabel
        isFocused={isFocused}
        value={value}
      >
        {label}
      </FormLabel>

      <Status>
        {isError && (<ErrorSign />)}

        {isValid && (<SuccessSign />)}
      </Status>
    </Content>
  </Container>
);

export default flow(
  FocusableComponent,
)(Input);
