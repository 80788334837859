import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: ${theme.colors.white};
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
`;

const Content = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Path = styled.path`
  stroke: currentColor;
  fill: none;
  stroke-width: 2.2;
  stroke-linecap: round;
`;

const Preloader = () => (
  <Container>
    <Content viewBox="0 0 36 36">
      <Path
        strokeDasharray="78, 100"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </Content>
  </Container>
);

export default Preloader;
