import { rgba } from 'polished';

const cerulean = '#03A9F4';
const carnation = '#FE5759';
const persimmon = '#FF5453';
const roseWhite = '#FFFBFB';
const shuttleGray = '#5C6B7E';
const mineShaft = '#333333';
const black = '#000000';
const iron = '#DEE1E5';
const white = '#FFFFFF';
const california = '#FF9802';
const athensGray = '#F4F5F8';
const lightGray = '#A3ACB7';

const inputPadding = '20px';
const inputPaddingRight = '45px';
const inputHeight = '62px';
const filledInputPadding = '14px';

const iframeSpacing = '15px';

const fontFamily = 'Helvetica, Arial, sans-serif';

const formLabel = () => `
  position: absolute;
  display: block;
  width: 100%;
  top: 50%;
  left: 0;
  padding-left: ${inputPadding};
  padding-right: ${inputPadding};
  font-size: 16px;
  color: ${shuttleGray};
  line-height: 1;
  transform: translateY(-50%);
  pointer-events: none;
  will-change: font-size, margin-top;
  transition: font-size 0.2s ease, margin-top 0.2s ease;
`;

const formField = () => `
  display: block;
  width: 100%;
  height: ${inputHeight};
  font-family: ${fontFamily};
  font-size: 16px;
  line-height: normal;
  color: ${mineShaft};
  border-width: 1px;
  border-style: solid;
  border-color: ${iron};
  background-color: ${white};
  border-radius: 2px;
  transition: border-color 0.2s ease;

  :hover {
      border-color: ${iron};
  }

  :focus {
    border-color: ${rgba(shuttleGray, 0.43)};
  }
`;

const fieldStatus = () => `
  position: absolute;
  display: block;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const fieldWithLabel = () => `
  padding-top: ${filledInputPadding};
`;

const fieldWithError = () => `
  border-color: ${rgba(persimmon, 0.2)};
  background-color: ${roseWhite};
`;

const errorPlacement = () => `
  position: absolute;
`;

const theme = {
  fontFamily,
  colors: {
    cerulean,
    carnation,
    shuttleGray,
    mineShaft,
    persimmon,
    roseWhite,
    california,
    white,
    iron,
    athensGray,
    black,
    lightGray,
  },
  breakpoints: {
    // XXS
    // XS
    S: 'max-width: 767px',
    // M
    L: 'max-width: 1024px',
    XL: 'max-width: 1200px',
    XXL: 'max-width: 1260px',
  },
  hover: 'not all and (pointer: coarse)',
  transition: '0.3s ease',
  formLabel,
  inputPadding,
  inputPaddingRight,
  filledInputPadding,
  formField,
  fieldStatus,
  fieldWithLabel,
  fieldWithError,
  errorPlacement,
  iframeSpacing,
};

export default theme;
