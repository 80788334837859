import React from 'react';
import flow from 'lodash/flow';
import styled from 'styled-components';
import { rgba } from 'polished';
import FormAlert from '../FormAlert';
import ErrorSign from '../ErrorSign';
import SuccessSign from '../SuccessSign';
import FocusableComponent from '../../HOC/FocusableComponent';
import FormLabel from '../FormLabel';
import theme from '../../theme';

interface BasicFieldProps {
  isError: boolean,
  disabled: boolean,
}

interface FormTextAreaProps {
  id: string,
  name: string,
  label?: string,
  value?: string,
  errorMsg?: string,
  isError?: boolean,
  isValid?: boolean,
  isDisabled?: boolean,
  isFocused: boolean,
  onChange: () => void,
  onBlur: () => void,
  onFocus: () => void,
}

const Container = styled.div``;

const Wrapper = styled.div``;

const Content = styled.div`
  position: relative;
  display: block;
`;

const TextAreaLabel = styled(FormLabel)`
  top: 23px;
  transform: translateY(0);
`;

const Status = styled.div`
  ${theme.fieldStatus()}
`;

const Footer = styled.div`
  ${theme.errorPlacement()}
`;

const BasicField = styled.textarea`
  margin: 0;
  padding: ${theme.filledInputPadding} ${theme.inputPaddingRight} 0 ${theme.inputPadding};
  appearance: none;
  outline: none;
  resize: none;

  ${theme.formField()}
`;

const Field = styled(BasicField)`
  padding-top: 25px;
  height: 108px;
  line-height: 1.3;

  ${(props: BasicFieldProps) => {
    if (!props.isError) return '';

    return theme.fieldWithError();
  }}

  ${(props: BasicFieldProps) => {
    if (!props.disabled) return '';

    return `
        border-color: ${rgba(theme.colors.iron, 0.46)};
        background-color: ${theme.colors.athensGray};
        cursor: not-allowed;
      `;
  }}

  @media (${theme.breakpoints.S}) {
    height: 90px;
  }
`;

const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  id,
  label,
  value,
  isError,
  errorMsg,
  isValid,
  isFocused,
  isDisabled,
  onChange,
  onBlur,
  onFocus,
}) => (
  (
    <Container>
      <Wrapper>
        <Content>
          <Field
            name={name}
            id={id}
            value={value}
            className="FormTextArea__input"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            isError={isError}
            disabled={isDisabled ? 'disabled' : undefined}
          />

          <TextAreaLabel
            isFocused={isFocused}
            value={value}
          >
            {label}
          </TextAreaLabel>

          <Status>
            {isError && (<ErrorSign />)}

            {isValid && (<SuccessSign />)}
          </Status>
        </Content>

        {isError && (<Footer><FormAlert text={errorMsg || ''} /></Footer>)}
      </Wrapper>
    </Container>
  )
);

export default flow(
  FocusableComponent,
)(FormTextArea);
