import React from 'react';
import styled from 'styled-components';
import { rgba, darken } from 'polished';
import theme from '../../theme';
import { informParent } from '../../utils';

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.iframeSpacing} ${theme.iframeSpacing} 0 ${theme.iframeSpacing};
  padding: 11px 18px;
  appearance: none;
  font-size: 16px;
  line-height: 1;
  color: ${theme.colors.white};
  border: none;
  background-color: ${theme.colors.lightGray};
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 ${rgba(theme.colors.black, 0.31)};
  transition: background-color ${theme.transition};

  :hover {
    background-color: ${darken(0.03, theme.colors.lightGray)};
  }

  @media (max-width: 54px) { // from tablets its iframe is 54px
    margin: 15px 0 0;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 6px;

  @media (max-width: 54px) { // from tablets its iframe is 54px
    margin-right: 0;
  }
`;

const Svg = styled.svg`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

const Path = styled.path`
  fill: currentColor;
`;

const Text = styled.div`
  @media (max-width: 54px) { // from tablets its iframe is 54px
    display: none;
  }
`;

const onClick = () => {
  const event = document.createEvent('Event');

  event.initEvent('reporter:expand', true, true);

  informParent(event);
};

const ExpandButton = () => (
  <Container onClick={onClick}>
    <Icon>
      <Svg version="1.1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20">
        <Path d="M9.97,0.5c5.229,0,9.469,4.24,9.469,9.47c0,5.23-4.24,9.47-9.469,9.47
          c-1.724,0-3.382-0.469-4.845-1.342l0.186,0.105l-4.048,1.086c-0.316,0.086-0.61-0.148-0.629-0.449l0.002-0.084l0.015-0.078
          l1.085-4.05l-0.074-0.13c-0.699-1.277-1.098-2.691-1.155-4.16l0,0L0.5,9.97C0.5,4.74,4.74,0.5,9.97,0.5z M9.97,1.5
          C5.292,1.5,1.5,5.292,1.5,9.97c0,1.54,0.419,3.022,1.202,4.332c0.069,0.116,0.088,0.255,0.054,0.386l0,0l-0.915,3.41l3.412-0.912
          c0.104-0.029,0.214-0.021,0.314,0.018l0,0l0.072,0.035c1.31,0.782,2.792,1.201,4.332,1.201c4.677,0,8.469-3.792,8.469-8.47
          C18.439,5.292,14.647,1.5,9.97,1.5z M14,12.5c0.276,0,0.5,0.224,0.5,0.5c0,0.246-0.177,0.449-0.41,0.492L14,13.5H6
          c-0.276,0-0.5-0.224-0.5-0.5c0-0.246,0.177-0.449,0.41-0.492L6,12.5H14z M14,10c0.276,0,0.5,0.224,0.5,0.5
          c0,0.246-0.177,0.449-0.41,0.492L14,11H6c-0.276,0-0.5-0.224-0.5-0.5c0-0.246,0.177-0.45,0.41-0.492L6,10H14z M14,7.5
          c0.276,0,0.5,0.224,0.5,0.5c0,0.246-0.177,0.45-0.41,0.492L14,8.5H6C5.724,8.5,5.5,8.276,5.5,8c0-0.246,0.177-0.45,0.41-0.492L6,7.5
          H14z"
        />
      </Svg>
    </Icon>

    <Text>
      Rapporter feil
    </Text>
  </Container>
);

export default ExpandButton;
