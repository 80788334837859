import styled from 'styled-components';
import { rgba } from 'polished';
import theme from '../../theme';

interface BasicFieldProps {
  isError: boolean,
  disabled?: boolean,
}

const BasicField = styled.input`
  margin: 0;
  padding: ${theme.filledInputPadding} ${theme.inputPaddingRight} 0 ${theme.inputPadding};
  appearance: none;
  outline: none;

  ${theme.formField()}

  @media (${theme.breakpoints.S}) {
    height: 56px;
  }
`;

const ErrorField = styled(BasicField)`
  ${(props: BasicFieldProps) => {
    if (!props.isError) return '';

    return theme.fieldWithError();
  }}
`;

const DisabledField = styled(ErrorField)`
  ${(props: BasicFieldProps) => {
    if (!props.disabled) return '';

    return `
      border-color: ${rgba(theme.colors.iron, 0.46)};
      background-color: ${theme.colors.athensGray};
      cursor: not-allowed;
    `;
  }}
`;

const FormField = styled(DisabledField)``;

export default FormField;
