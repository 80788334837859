import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface ErrorSignProps {
  size?: 'small' | 'default' | 'big',
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 19px;
  color: ${theme.colors.carnation};

  ${(props: ErrorSignProps) => {
    if (props.size === 'small') {
      return `
        width: 16px;
        height: 16px;
      `;
    }

    return '';
  }}
`;

const Icon = styled.svg`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

const Path = styled.path`
  fill: currentColor;
`;

const ErrorSign: React.FC<ErrorSignProps> = () => (
  <Container>
    <Icon x="0px" y="0px" width="25px" height="23px" viewBox="0 0 25 23">
      <Path d="M12.5,16.9c0.553,0,1,0.447,1,1c0,0.552-0.447,1-1,1c-0.552,0-1-0.448-1-1
          C11.5,17.348,11.948,16.9,12.5,16.9z"
      />

      <Path d="M12.464,8c-0.498,0-0.889,0.408-0.875,0.887l0.195,6.227C11.8,15.604,12.221,16,12.499,16c0,0-0.697,0,0,0l0,0c0.368,0,0.679-0.408,0.693-0.887l0.19-6.227C13.397,8.397,13.018,8,12.508,8H12.464z" />

      <Path d="M22.406,22.301H2.594c-0.662,0-1.265-0.337-1.612-0.9c-0.348-0.564-0.377-1.255-0.079-1.847l10-17.838c0.312-0.619,0.962-1.019,1.685-1.019c0.719,0,1.362,0.395,1.676,1.031l9.824,17.812c0.307,0.616,0.273,1.304-0.074,1.865C23.667,21.966,23.065,22.301,22.406,22.301z M12.588,1.997c-0.087,0-0.384,0.023-0.538,0.329L2.064,20.139c-0.135,0.268-0.028,0.494,0.024,0.579C2.142,20.803,2.295,21,2.594,21h19.812c0.298,0,0.451-0.195,0.504-0.28c0.052-0.084,0.16-0.31,0.027-0.577L13.112,2.33C12.958,2.019,12.672,1.997,12.588,1.997z" />
    </Icon>
  </Container>
);

export default ErrorSign;
